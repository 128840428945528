import { Maybe, OrderStatusNumber, OrderStatusString } from './common'

export interface ImageData {
   storage_slug: string
   storage_location: string
   image_size: number
   created_at: number
   _id: string
}

export interface ProducTypePrototype {
   slug: string
   title: string
}

export interface ProductTypeData {
   _id: string
   product_type_name: string
   // product_type_prototype: ProducTypePrototype[]
   created_at?: number
}

export interface ProductMetaDataOption {
   name: string
   slug: string
}

export interface ProductMetaData {
   slug: string
   title: string
   value: ProductMetaDataOption
}

export interface PromotionProgramData {
   _id: string
   flash_sale_name: string
   list_products: ProductData[]
   start_at: number
   end_at: number
   created_at: number
}

export interface SelectedProductMetadata {
   slug: string
   title: string
   value: {
      attribute_slug: string
      attribute_title: string
      name: string
      slug: string
   }
}

interface OrderItem {
   compare_at_price: number
   final_price: number
   image: string[]
   item_name: string
   product_id: string
   product_metadata_selected: SelectedProductMetadata[]
   promotion_program_id: Maybe<string>
   quantity: number
}

export interface AddressDetail {
   address_line_1: string
   city: string
   state: string
   country: string
}

export enum PaymentMethodEnum {
   cod = 'cod',
   transfer = 'transfer',
}
export interface OrderData {
   _id: string
   user_id: Maybe<string>
   order_items: OrderItem[]
   grand_total: number
   voucher_code: string
   note: string
   payment_method: PaymentMethodEnum
   status: OrderStatusNumber
   address_detail: AddressDetail
   phone_number: string
   created_at: number
   updated_at: number
   customer_name: string
}

export type FlashSaleData = {
   status: boolean
   id_flash_sale: string
   price_sale: number
   percentage: number
   start_at: number
   end_at: number
}
export interface ProductData {
   _id?: string
   product_name: string
   product_type: string
   image: string[]
   price: number
   product_instruction: string
   product_description: string
   product_short_description?: string
   creator?: string | null
   slug?: string
   product_metadata: ProductMetaData[]
   created_at?: number
   updated_at?: number
   variants?: ProductData[]
   rating?: number
   uid?: string
   stock_status?: boolean
   not_sellable?: boolean
   flash_sale?: Maybe<FlashSaleData>
}

export type WrappedProductData = {
   product: ProductData
   variants: ProductData[]
   promotion: PromotionData[]
}

export enum ManagerPermission {
   ADMIN = 'admin',
   SHOP_KEEPER = 'shop_keeper',
   PACKAGE_MAN = 'package_man',
}

export interface ManagerData {
   _id: string
   email: string
   password: string
   permission: ManagerPermission
   is_active: boolean
   created_at: number
   updated_at: number
}

export interface UserData {
   _id: string
   email: string
   password: string
   created_at: number
   updated_at: number
   is_verified: {
      status: boolean
      code: Maybe<string>
      start_time: Maybe<number>
      end_time: Maybe<null>
   }
   status: boolean
}

export interface PromotionData {
   _id: string
   product_id: ProductData
   promotion_program_id: PromotionProgramData
   created_at: number
}

export interface VoucherData {
   _id: string
   manager_id: string
   voucher_code: string
   value: string
   is_valid: boolean
   created_at: number
   expired_at: number
}

export interface SoldProductData {
   product_id: string
   quantity: number
}

export interface RevenueData {
   all_revenue: number
}

export interface BillData {
   shipped_order: number
   cancelled_order: number
   onhold_order: number
   success_order: number
   total_order: number
}

export interface BannerData {
   _id: string
   button_text: string
   button_link: string
   image_id: string
   product: string
   large_text_1: string
   image: string
   small_text_1: string
}

export interface VariantData extends ProductData {
   identifier: string
}

export enum StockStatusEnum {
   IN_STOCK = 'In stock',
   OUT_OF_STOCK = 'Out of stock',
}

export const stockStatusTrans = {
   [StockStatusEnum.IN_STOCK]: 'Còn hàng',
   [StockStatusEnum.OUT_OF_STOCK]: 'Hết hàng',
}

export type SoftwareData = {
   _id: string
   title: string
   description: string
   thumbnail: string
   created_at: string
   downloadLink: string
}

export type SetupPostData = {
   _id: string
   title: string
   description: string
   url: string
   created_at: string
   thumbnail: string
   content: string
}

export type DeviotVideo = {
   _id?: Maybe<string>
   title?: Maybe<string>
   description?: Maybe<string>
   urlVideoIntro?: Maybe<string>
   slug?: Maybe<string>
   created_at?: Maybe<string>
   updated_at?: Maybe<string>
   __v?: Maybe<number>
}

export type BrandData = {
   _id: string
   name: string
   email: string
   phone: string
   address: string
   description: string
   thumbnail: string
   status: StatusSaleAgent
}

export enum StatusSaleAgent {
   APPROVED = 'APPROVED',
   REJECT = 'REJECT',
   PENDING = 'PENDING',
}

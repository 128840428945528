import { FC, useMemo } from 'react'
import { colorPalette } from '../../../config'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../../components/Table'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg'
import { getOrderStatus } from '../../../utils'
import { OrderData, OrderStatusString } from '../../../types'
import { useTranslation } from 'react-i18next'
import useFilteredQuery from '../../../hooks/use-swr/use-query-with-filter'

const OrdersTable: FC = () => {
   const { t } = useTranslation(['common', 'rows', 'components'])
   const {
      filter,
      controlMethods: {
         handleChangeEndDate,
         handleChangePageNum,
         handleChangePageSize,
         handleChangeStartDate,
         handleAddSearchCriteria,
         handleRemoveSearchCriteria,
      },
      response: { isLoading, data: orders, paginationInfo },
   } = useFilteredQuery<OrderData[]>({ url: 'order' })

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: '',
      },
      {
         id: 'order_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'Mã đơn hàng',
      },
      {
         id: 'customer_name',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'Tên khách',
      },
      {
         id: 'phone_number',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: t('order.phone', { ns: 'rows' }),
      },
      // {
      //    id: 'address',
      //    numeric: false,
      //    disablePadding: false,
      //    filterType: FilterType.TEXT,
      //    label: 'Địa chỉ',
      // },
      {
         id: 'grand_total',
         numeric: true,
         disablePadding: false,
         filterType: FilterType.RANGE,
         label: t('order.total', { ns: 'rows' }),
      },
      {
         id: 'status',
         numeric: true,
         disablePadding: false,
         filterType: FilterType.OPTION,
         label: t('common.status', { ns: 'rows' }),
      },
      {
         id: 'created_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.createdAt', { ns: 'rows' }),
      },
   ]

   const handleOnSearch = (searchTerm: string, type: string) => {
      if (!searchTerm) {
         return handleRemoveSearchCriteria(type)
      }

      return handleAddSearchCriteria(type, searchTerm)
   }

   const handleOnPageNumChange = (pageNum: number) => {
      handleChangePageNum(pageNum)
   }

   const handleOnPageSizeChange = (pageSize: number) => {
      handleChangePageSize(pageSize)
   }

   const rows: TableRowType[] = useMemo(
      () =>
         orders
            ? orders?.map((order) => ({
                 key: order._id,
                 cells: [
                    {
                       identifier: '_id',
                       type: TableCellDataType.ID,
                       data: order._id,
                    },
                    {
                       identifier: 'order_id',
                       type: TableCellDataType.TEXT,
                       data: order._id,
                    },
                    {
                       identifier: 'customer_name',
                       type: TableCellDataType.TEXT,
                       data:
                          order.customer_name ||
                          (t('table.guest', { ns: 'components' }) as string),
                    },
                    {
                       identifier: 'phone_number',
                       type: TableCellDataType.TEXT,
                       data: order.phone_number,
                    },
                    {
                       identifier: 'grand_total',
                       type: TableCellDataType.UNIT,
                       data: order.grand_total,
                       unit: 'đ',
                    },
                    {
                       identifier: 'status',
                       type: TableCellDataType.TAG,
                       data: t(
                          `status.${getOrderStatus(order.status)}`
                       ) as string,
                       tagColorMap: [
                          {
                             value: t(
                                `status.${OrderStatusString.ORDER_CANCELLED}`
                             ),
                             color: 'error',
                          },
                          {
                             value: t(
                                `status.${OrderStatusString.ORDER_ONHOLD}`
                             ),
                             color: 'warning',
                          },
                          {
                             value: t(
                                `status.${OrderStatusString.ORDER_SHIPPED}`
                             ),
                             color: 'success',
                          },
                          {
                             value: t(
                                `status.${OrderStatusString.ORDER_SUCCESS}`
                             ),
                             color: 'info',
                          },
                       ],
                    },
                    {
                       identifier: 'created_at',
                       type: TableCellDataType.DATE,
                       data: order.created_at,
                    },
                    {
                       identifier: 'action',
                       type: TableCellDataType.ACTION,
                       data: [
                          {
                             icon: {
                                src: PenIcon,
                                color: colorPalette.grey,
                                tooltip: t('tooltip.common.edit', {
                                   ns: 'components',
                                }) as string,
                             },
                             link: `/orders/${order._id}`,
                          },
                       ],
                    },
                 ],
              }))
            : [],
      [orders, t]
   )

   return (
      <PageWrapper>
         <PageHeader>
            <PageTitle type='header' title={t('pageTitle.order.all')} />
         </PageHeader>
         <PageContent>
            <Table
               loading={isLoading}
               initialSort='created_at'
               title={t('tableTitle.order')}
               initialSearchTermType='order_id'
               headCells={headCells}
               rows={rows}
               disableFuture
               totalPage={paginationInfo?.pageTotal}
               totalRecords={paginationInfo?.totalDocuments}
               pageNum={filter?.pageNum}
               pageSize={filter?.pageSize}
               onEndDateChange={handleChangeEndDate}
               onSearchTermChange={handleOnSearch}
               onStartDateChange={handleChangeStartDate}
               onPageNumChange={handleOnPageNumChange}
               onPageSizeChange={handleOnPageSizeChange}
               withAction={true}
            />
         </PageContent>
      </PageWrapper>
   )
}

export default OrdersTable

import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { PromotionData, PromotionProgramData, Status } from '../../types'
import { setResponse, setUploading } from '../fetch'

export interface GetAllVouchersData {
   pageSize: number
   pageNum: number
}
export interface PromotionProgramCreate {
   flash_sale_name: string
   start_at: number
   end_at: number
   list_products: {
      _id: string
      price_sale: number
      percentage: number
   }[]
}

export interface PromotionProgramUpdate extends PromotionProgramCreate {
   promotionId: string
}

export interface AddToPromotionProgramData {
   product_ids: string[]
}

export interface AddToPromotionProgram<T> {
   program_id: string
   payload: T
}

export interface RevokeVoucherData {
   voucher_code: string
}

export interface CreateVoucherData {
   value: number
   quantity: number
   expired_at: number
}

export const getAllPromotions = createAsyncThunk(
   'promotionState/getAllPromotions',
   async (undefined, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<PromotionData[]>({
            url: 'promotion',
            secured: true,
         })

         return {
            promotions: res?.data,
         }
      } catch (err: any) {
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const getAllPromotionPrograms = createAsyncThunk(
   'promotionState/getAllPromotionPrograms',
   async (undefined, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<PromotionProgramData[]>({
            url: 'flash-sale',
            secured: true,
         })

         return {
            promotionPrograms: res?.data,
         }
      } catch (err: any) {
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const getAllVouchers = createAsyncThunk(
   'promotionState/getAllVouchers',
   async ({ pageSize, pageNum }: GetAllVouchersData, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<PromotionProgramData[]>({
            url: `voucher?pageSize=${pageSize}&pageNum=${pageNum}`,
            secured: true,
         })

         return {
            vouchers: res?.data,
         }
      } catch (err: any) {
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const createPromotionProgram = createAsyncThunk(
   'promotionState/createPromotionProgram',
   async (value: PromotionProgramCreate, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await postHandler<PromotionProgramCreate, any>({
            url: 'flash-sale',
            secured: true,
            body: value,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const addToPromotionProgram = createAsyncThunk(
   'promotionState/addToPromotionProgram',
   async (
      { program_id, payload }: AddToPromotionProgram<AddToPromotionProgramData>,
      { dispatch }
   ) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await putHandler<AddToPromotionProgramData, any>({
            url: `promotion/${program_id}/add`,
            secured: true,
            body: payload,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const revokeVoucher = createAsyncThunk(
   'promotionState/revokeVoucher',
   async (voucherCode: string, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await postHandler<RevokeVoucherData, any>({
            url: 'voucher/invalidate',
            secured: true,
            body: { voucher_code: voucherCode },
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const createVoucher = createAsyncThunk(
   'promotionState/createVoucher',
   async (values: CreateVoucherData, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const queue = []
         for (let i = 0; i < values.quantity; i++) {
            queue.push(
               postHandler<CreateVoucherData, any>({
                  url: 'voucher',
                  secured: true,
                  body: values,
               })
            )
         }

         const res = await Promise.all(queue)

         dispatch(setUploading(false))

         if (res.every(({ statusCode }) => statusCode === 200)) {
            dispatch(
               setResponse({
                  status: Status.SUCCESS,
                  message: res[0].message,
               })
            )
         } else {
            dispatch(
               setResponse({
                  status: Status.ERROR,
                  message:
                     res[0].message === 'THE_TIME_MUST_BE_ON_FUTURE'
                        ? 'Thời gian phải lớn hơn hiện tại'
                        : res[0].message,
               })
            )
         }
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const deletePromotionProgram = createAsyncThunk(
   'promotionState/deletePromotionProgram',
   async (id: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await deleteHandler<any>({
            url: `flash-sale/${id}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const getOnePromotionProgram = createAsyncThunk(
   'promotionState/getOnePromotionProgram',
   async (id: string, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await getHandler<PromotionProgramData>({
            url: `flash-sale/${id}`,
            secured: true,
         })

         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )

         return {
            programDetail: res?.data,
         }
      } catch (err: any) {
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const updatePromotionProgram = createAsyncThunk(
   'promotionState/updatePromotionProgram',
   async ({ promotionId, ...value }: PromotionProgramUpdate, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await putHandler<PromotionProgramCreate, any>({
            url: `flash-sale/${promotionId}`,
            secured: true,
            body: value,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

import {
   OrderStatusNumber,
   OrderStatusString,
   PaymentMethodEnum,
} from '../types'

export const getOrderStatus = (statusNum: OrderStatusNumber) => {
   switch (statusNum) {
      case OrderStatusNumber.ORDER_SUCCESS:
         return OrderStatusString.ORDER_SUCCESS
      case OrderStatusNumber.ORDER_ONHOLD:
         return OrderStatusString.ORDER_ONHOLD
      case OrderStatusNumber.ORDER_CANCELLED:
         return OrderStatusString.ORDER_CANCELLED
      case OrderStatusNumber.ORDER_SHIPPED:
         return OrderStatusString.ORDER_SHIPPED
      default:
         return 'Invalid status'
   }
}

export const getPaymentMethod = (paymentMethod: PaymentMethodEnum) => {
   switch (paymentMethod) {
      case PaymentMethodEnum.cod:
         return 'Thanh toán khi nhận hàng'
      case PaymentMethodEnum.transfer:
         return 'Chuyển khoản ngân hàng'
      default:
         return 'Chưa có hình thức thanh toán'
   }
}

import {
   BaseSyntheticEvent,
   FC,
   ReactNode,
   createContext,
   useCallback,
   useContext,
   useEffect,
   useMemo,
   useState,
} from 'react'
import {
   getAllProductTypes,
   selectFetch,
   useAppDispatch,
   useAppSelector,
} from '../redux'
import { Status, Maybe, VariantData, ProductMetaData } from '../types'
import {
   Control,
   FieldErrors,
   UseFormGetValues,
   UseFormReset,
   UseFormSetValue,
   UseFormWatch,
   useForm,
   useWatch,
} from 'react-hook-form'
import {
   ProductAttribute,
   generateSlugsForAttributes,
   generateVariantsFromAttributes,
} from '../utils/generate-variant-from-attributes'
import { yupResolver } from '@hookform/resolvers/yup'
import { productCreateFormSchema } from '../schemas'
import { useDebounce } from './use-debounce'

export interface ProductFormValues {
   _id?: string
   uid?: string
   rating?: number
   product_name: string
   product_type: string
   image: string[]
   price: number
   product_metadata: ProductAttribute[]
   product_instruction: string
   product_description: string
   product_short_description: string
   stock_status: boolean
   not_sellable: boolean
}

export const defaultProductFormValues: ProductFormValues = {
   product_name: '',
   product_type: '',
   price: 0,
   image: [],
   rating: 1,
   product_metadata: [
      {
         slug: '',
         title: '',
         values: [
            {
               name: '',
               slug: '',
            },
         ],
      },
   ],
   product_description: '',
   product_instruction: '',
   product_short_description: '',
   _id: '',
   uid: '',
   not_sellable: false,
   stock_status: true,
}

export type ProductFormControl = {
   isDirty: boolean
   errors: FieldErrors<ProductFormValues>
   control: Control<ProductFormValues, any>
   reset: UseFormReset<ProductFormValues>
   watch: UseFormWatch<ProductFormValues>
   setValue: UseFormSetValue<ProductFormValues>
   getValues: UseFormGetValues<ProductFormValues>
}

export type VariantController = {
   setVariantPrice: (variant_identifier: string, price: number) => void
   setVariantImages: (variant_identifier: string, images: string[]) => void
   setVariantName: (variant_identifier: string, name: string) => void
   setInitVariants: (variants: VariantData[]) => void
   toggleStatus: (
      variant_identifier: string,
      field: 'stock_status' | 'not_sellable',
      status: boolean
   ) => void
}

export type VariantFormValues = {
   product_name: string
   price: string
   image: string[]
}

type HandleOnSubmitProducts = (variants: VariantData[]) => void

export type ProductsFormContextValues = Maybe<{
   variants: VariantData[]
   formControl: ProductFormControl
   variantController: VariantController
   // debouncedProductAttributes: ProductAttribute[]
   onSubmit: (data: ProductFormValues) => void
   handleOnSubmit: (
      e?: BaseSyntheticEvent<object, any, any> | undefined
   ) => Promise<void>
}>

const ProductsFormContext = createContext<ProductsFormContextValues>(null)

type UseProductsFormContext = () => ProductsFormContextValues

type ProductsFormWrapperProps = {
   values?: ProductFormValues
   action: HandleOnSubmitProducts
   children: ReactNode
}

export const ProductsFormWrapper: FC<ProductsFormWrapperProps> = ({
   values,
   action,
   children,
}) => {
   const { response } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()

   const [variants, setVariants] = useState<VariantData[]>([])

   const {
      control,
      handleSubmit,
      reset,
      getValues,
      setValue,
      watch,
      formState: { errors, isDirty },
   } = useForm<ProductFormValues>({
      defaultValues: defaultProductFormValues,
      values,
      resolver: yupResolver(productCreateFormSchema),
   })
   const productAttributes = useWatch({
      control,
      name: 'product_metadata',
   })
   const debouncedProductAttributes = useDebounce(productAttributes, 1000)

   const onSubmit = ({
      product_description,
      product_instruction,
      product_short_description,
      product_type,
      product_name,
      rating,
      image,
      _id,
   }: ProductFormValues) => {
      if (variants.length > 0) {
         action(
            variants.map(
               ({
                  product_type: oldType,
                  product_description: oldDesc,
                  product_instruction: oldInstruc,
                  product_short_description: oldShortDesc,
                  product_name: oldName,
                  image: oldImage,
                  rating: oldRating,
                  ...variant
               }) => {
                  if (_id === variant._id) {
                     return {
                        product_type,
                        product_description,
                        product_instruction,
                        product_short_description,
                        product_name,
                        rating,
                        image: [...oldImage, ...image].filter(
                           (img) => typeof img !== 'undefined'
                        ),
                        ...variant,
                     }
                  }

                  return {
                     product_type,
                     product_description,
                     product_instruction,
                     product_short_description,
                     rating,
                     image: [...oldImage, ...image].filter(
                        (img) => typeof img !== 'undefined'
                     ),
                     product_name: oldName,
                     ...variant,
                  }
               }
            )
         )
      }
   }

   const handleOnSubmit = handleSubmit(onSubmit)

   const toggleStatus = (
      variant_identifier: string,
      field: 'stock_status' | 'not_sellable',
      status: boolean
   ) => {
      setVariants((prevVariants) =>
         prevVariants.map((variant) =>
            variant.identifier === variant_identifier
               ? {
                    ...variant,
                    [field]: status,
                 }
               : variant
         )
      )
   }

   const setVariantPrice = (variant_identifier: string, price: number) => {
      setVariants((prevVariants) =>
         prevVariants.map((variant) =>
            variant.identifier === variant_identifier
               ? {
                    ...variant,
                    price,
                 }
               : variant
         )
      )
   }

   const setVariantName = (variant_identifier: string, name: string) => {
      setVariants((prevVariants) =>
         prevVariants.map((variant) =>
            variant.identifier === variant_identifier
               ? {
                    ...variant,
                    product_name: name,
                 }
               : variant
         )
      )
   }

   const setVariantImages = (variant_identifier: string, images: string[]) => {
      setVariants((prevVariants) =>
         prevVariants.map((variant) =>
            variant.identifier === variant_identifier
               ? {
                    ...variant,
                    image: images.filter((img) => !!img),
                 }
               : variant
         )
      )
   }

   const setInitVariants = (variants: VariantData[]) => {
      setVariants(variants)
   }

   const formControl = useMemo(
      () => ({
         control,
         reset,
         watch,
         errors,
         isDirty,
         setValue,
         getValues,
      }),
      [control, reset, watch, errors, isDirty, setValue, getValues]
   )

   const variantController = useMemo(
      () => ({
         setVariantImages,
         setVariantName,
         toggleStatus,
         setVariantPrice,
         setInitVariants,
      }),
      []
   )

   const generateVariantsForProduct = useCallback(
      (debouncedProductAttributes: ProductAttribute[]) => {
         setVariants((prevVariants) => {
            const variantsMetadata = generateVariantsFromAttributes(
               generateSlugsForAttributes(debouncedProductAttributes)
            )
            const { _id, ...productData } = formControl.getValues()
            const variantMap = new Map<string, any>()
            prevVariants.forEach(({ identifier, ...restVariantFields }) =>
               variantMap.set(identifier, restVariantFields)
            )
            const mergedVariants = variantsMetadata.map((metadata) => {
               let identifiers: string[] = []
               let product_metadata: ProductMetaData[] = []

               metadata.forEach((data) => {
                  product_metadata.push({
                     slug: data.attribute_slug,
                     title: data.attribute_title,
                     value: data,
                  })
                  identifiers.push(`${data.attribute_slug}-${data.slug}`)
               })

               return {
                  ...productData,
                  image: [],
                  identifier: identifiers.join('_'),
                  product_metadata,
               }
            })

            const hasAttributes = debouncedProductAttributes.every((attr) =>
               attr.values.every((val) => !!val.name)
            )

            if (hasAttributes) {
               return mergedVariants.map((variant) => {
                  const existedRestVarianFields = variantMap.get(
                     variant.identifier
                  )
                  return existedRestVarianFields
                     ? {
                          identifier: variant.identifier,
                          ...existedRestVarianFields,
                       }
                     : variant
               })
            }

            return prevVariants
         })
      },
      [formControl]
   )

   useEffect(() => {
      generateVariantsForProduct(debouncedProductAttributes)
   }, [debouncedProductAttributes, generateVariantsForProduct])

   useEffect(() => {
      dispatch(getAllProductTypes())
   }, [])

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   return (
      <ProductsFormContext.Provider
         value={{
            variants,
            formControl,
            variantController,
            // debouncedProductAttributes,
            onSubmit,
            handleOnSubmit,
         }}
      >
         {children}
      </ProductsFormContext.Provider>
   )
}

export const useProductsFormContext: UseProductsFormContext = () =>
   useContext(ProductsFormContext)

export const ProductsFormHOC = (
   Component: FC,
   action: HandleOnSubmitProducts
) => {
   return (
      <ProductsFormWrapper action={action}>
         <Component />
      </ProductsFormWrapper>
   )
}

import { FC, useEffect, useMemo, useState } from 'react'
import { CircleLoading, PopupNoti } from '../../../components'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../../components/Table'
import { PageContent } from '../../../layout'
import {
   getAllVouchers,
   revokeVoucher,
   selectFetch,
   selectPromotion,
   useAppDispatch,
   useAppSelector,
   resetVouchers,
} from '../../../redux'
import { VoucherData, VoucherStatus } from '../../../types'
import { FormName, OutletTitle, useTitle } from '../Promotion'
import { ReactComponent as DeductionIcon } from '../../../assets/icons/deduction.svg'
import { colorPalette } from '../../../config'
import { warningMsg } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import useFilteredQuery from '../../../hooks/use-swr/use-query-with-filter'

const finalWarning = {
   __html: warningMsg.__html.replace('xoá', 'vô hiệu'),
}

const VouchersTable: FC = () => {
   const dispatch = useAppDispatch()
   const { promotionLoading } = useAppSelector(selectPromotion)
   const { uploading } = useAppSelector(selectFetch)
   const { setTitle, setFormName } = useTitle()
   const { t } = useTranslation(['common', 'rows', 'components'])

   const [selectedVoucher, setSelectedVoucher] = useState<string>('')
   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const {
      filter,
      controlMethods: {
         handleChangeEndDate,
         handleChangePageNum,
         handleChangePageSize,
         handleChangeStartDate,
         handleAddSearchCriteria,
         handleRemoveSearchCriteria,
      },
      response: {
         isLoading,
         isValidating,
         data: vouchers,
         paginationInfo,
         mutate,
      },
   } = useFilteredQuery<VoucherData[]>({ url: 'voucher' })

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'ID',
      },
      {
         id: 'voucher_code',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: t('voucher.code', { ns: 'rows' }),
      },
      {
         id: 'value',
         numeric: true,
         disablePadding: false,
         filterType: FilterType.RANGE,
         label: t('common.amount', { ns: 'rows' }),
      },
      {
         id: 'is_valid',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.OPTION,
         label: t('common.status', { ns: 'rows' }),
      },
      {
         id: 'created_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.createdAt', { ns: 'rows' }),
      },
      {
         id: 'expired_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.expiredAt', { ns: 'rows' }),
      },
   ]

   const handleOnClick = (code: string) => {
      setSelectedVoucher(code)
      setOpenAlert(true)
   }

   const handleRevoke = () => {
      if (vouchers) {
         dispatch(revokeVoucher(selectedVoucher))
      }
   }

   const handleOnSearch = (searchTerm: string, type: string) => {
      if (!searchTerm) {
         return handleRemoveSearchCriteria(type)
      }

      return handleAddSearchCriteria(type, searchTerm)
   }

   const handleOnPageNumChange = (pageNum: number) => {
      handleChangePageNum(pageNum)
   }

   const handleOnPageSizeChange = (pageSize: number) => {
      handleChangePageSize(pageSize)
   }

   useEffect(() => {
      if (!uploading && selectedVoucher) {
         mutate({
            data: vouchers?.reduce<VoucherData[]>((acc, cur) => {
               acc.push(
                  cur._id === selectedVoucher
                     ? { ...cur, is_valid: !cur.is_valid }
                     : cur
               )
               return acc
            }, []),
            statusCode: 200,
         })
         setSelectedVoucher('')
      }
   }, [uploading])

   const rows: TableRowType[] = useMemo(
      () =>
         vouchers
            ? vouchers.map((voucher) => ({
                 key: voucher._id,
                 cells: [
                    {
                       identifier: '_id',
                       type: TableCellDataType.ID,
                       data: voucher._id,
                    },
                    {
                       identifier: 'voucher_code',
                       type: TableCellDataType.TEXT,
                       data: voucher.voucher_code,
                    },
                    {
                       identifier: 'value',
                       type: TableCellDataType.UNIT,
                       data: voucher.value,
                       unit: 'đ',
                    },
                    {
                       identifier: 'is_valid',
                       type: TableCellDataType.TAG,
                       data: voucher.is_valid
                          ? voucher.expired_at >= new Date().valueOf()
                             ? 'Khả dụng'
                             : 'Hết hạn'
                          : 'Không hợp lệ',
                       tagColorMap: [
                          {
                             value: t(`status.${VoucherStatus.VALID}`),
                             color: 'success',
                          },
                          {
                             value: t(`status.${VoucherStatus.INVALID}`),
                             color: 'error',
                          },
                       ],
                    },
                    {
                       identifier: 'created_at',
                       type: TableCellDataType.DATE,
                       data: voucher.created_at,
                    },
                    {
                       identifier: 'expired_at',
                       type: TableCellDataType.DATE,
                       data: voucher.expired_at,
                    },
                    {
                       identifier: 'action',
                       type: TableCellDataType.ACTION,
                       data: [
                          {
                             icon: {
                                src: DeductionIcon,
                                color: voucher.is_valid
                                   ? colorPalette.red.shade_500
                                   : colorPalette.grey,
                                tooltip: t('tooltip.common.revoke', {
                                   ns: 'components',
                                }) as string,
                             },
                             disabled: !voucher.is_valid,
                             handleOnClick: () =>
                                handleOnClick(voucher.voucher_code),
                          },
                       ],
                    },
                 ],
              }))
            : [],
      [vouchers]
   )

   useEffect(() => {
      setTitle(t(`pageTitle.${OutletTitle.VOUCHERS}`) || '')
      setFormName(FormName.VOUCHER)
   }, [uploading, setTitle])

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={finalWarning}
            action={handleRevoke}
         />
         <PageContent>
            <Table
               loading={isLoading || promotionLoading || isValidating}
               initialSort='created_at'
               initialSearchTermType='voucher_code'
               title={t('tableTitle.voucher')}
               headCells={headCells}
               rows={rows}
               totalPage={paginationInfo?.pageTotal}
               totalRecords={paginationInfo?.totalDocuments}
               pageNum={filter?.pageNum}
               pageSize={filter?.pageSize}
               onEndDateChange={handleChangeEndDate}
               onSearchTermChange={handleOnSearch}
               onStartDateChange={handleChangeStartDate}
               onPageNumChange={handleOnPageNumChange}
               onPageSizeChange={handleOnPageSizeChange}
               withAction
            />
         </PageContent>
      </>
   )
}

export default VouchersTable

import { useMemo, forwardRef } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import hljs from 'highlight.js'
import javascript from 'highlight.js/lib/languages/javascript'
import Quill from 'quill'
import c from 'highlight.js/lib/languages/c'
import cpp from 'highlight.js/lib/languages/cpp'
import { Box } from '@mui/material'
import { colorPalette, typography } from '../../config'
import { HelperText } from '../Input/styles'
import QuillResizeImage from 'quill-resize-image'
// import ImageResize from 'quill-image-resize-module-react';
// import VideoResize from 'quill-video-resize-module';

import ResizeModule from '@botom/quill-resize-module'

Quill.register('modules/resize', ResizeModule)

// Quill.register('modules/VideoResize', VideoResize);
// Quill.register('modules/imageResize', ImageResize);

hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('c', c)
hljs.registerLanguage('cpp', cpp)

//@ts-ignore
window.hljs = hljs

hljs.configure({ languages: ['c', 'cpp', '1c'] })

const Size = Quill.import('attributors/style/size')

Size.whitelist = [
   '12px',
   '14px',
   '16px',
   '18px',
   '20px',
   '30px',
   '40px',
   '50px',
   '60px',
   '70px',
   '80px',
]

Quill.register(Size, true)
//@ts-ignore
Quill.register('modules/resize', QuillResizeImage)

const formats = [
   'header',
   'link',
   'image',
   'align',
   'font',
   'size',
   'bold',
   'italic',
   'underline',
   'strike',
   'blockquote',
   'list',
   'bullet',
   'indent',
   'video',
   'code-block',
   'color',
   'background',
   'emoji',
]

interface EditorProps extends ReactQuillProps {
   helperText?: string
}

const Editor = forwardRef((props: EditorProps, ref: any) => {
   const {
      theme,
      formats,
      modules: propsModule,
      helperText,
      ...restProps
   } = props

   const modules = useMemo(
      () => ({
         syntax: {
            highlight: (text: string) => hljs.highlightAuto(text).value,
         },
         resize: {
            locale: {
               // change them depending on your language
               altTip: 'Hold down the alt key to zoom',
               floatLeft: 'Left',
               floatRight: 'Right',
               center: 'Center',
               restore: 'Restore',
            },
         },
         // imageResize: {
         //    parchment: Quill.import('parchment'),
         //    modules: ['Resize', 'DisplaySize']
         // },
         // videoResize: {
         //    // parchment: Quill.import('parchment'),
         //    modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
         // },
         toolbar: {
            container: [
               [{ header: [1, 2, 3, 4, 5, 6, 0] }],
               ['bold', 'italic', 'underline', 'strike', 'blockquote'],
               [
                  { align: '' },
                  { align: 'center' },
                  { align: 'right' },
                  { align: 'justify' },
               ],
               [{ indent: '-1' }, { indent: '+1' }],
               ['link', 'image', 'video'],
               ['clean'],
               [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  {
                     color: [
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                     ],
                  },
                  { background: [] },
               ],
               ['code-block'],
               ['Font'],
               [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            ],
         },
      }),
      []
   )

   //  ref={quillRef} value={value} modules={modules} onChange={setValue}

   return (
      <Box>
         <ReactQuill
            theme='snow'
            ref={ref}
            formats={formats}
            modules={modules}
            {...restProps}
         />
         {helperText ? (
            <HelperText
               className={typography.mb.descSemi}
               color={colorPalette.red.shade_500}
            >
               {helperText}
            </HelperText>
         ) : null}
      </Box>
   )
})

export default Editor

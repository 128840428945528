import { useMemo } from 'react'
import { FilterControlMethods, useFilter } from '../use-filter'
import { useQuery } from './use-query'
import { AttributeQuery, Maybe } from '../../types'
import { APIReturnData, PaginationInfo } from '../use-fetch-handlers'
import { KeyedMutator, SWRConfiguration } from 'swr'

type UseFilteredQueryParams = {
   url: Maybe<string>
   options?: SWRConfiguration
}

type UseFilteredQueryReturn<ResultDataType> = {
   filter: Maybe<AttributeQuery>
   controlMethods: FilterControlMethods
   response: {
      isLoading: boolean
      data: ResultDataType | undefined
      paginationInfo?: PaginationInfo
      isValidating: boolean
      error: any
      mutate: KeyedMutator<APIReturnData<ResultDataType>>
   }
}

const useFilteredQuery = <ResultDataType>({
   url,
   options,
}: UseFilteredQueryParams): UseFilteredQueryReturn<ResultDataType> => {
   const {
      filter,
      handleChangeEndDate,
      handleChangePageNum,
      handleChangePageSize,
      handleChangeStartDate,
      handleAddSearchCriteria,
      handleRemoveSearchCriteria,
   } = useFilter()

   const queryParams = useMemo(() => {
      if (!filter) return ''
      const {
         start_date,
         end_date,
         pageNum: curPageNum,
         pageSize: curPageSize,
         search_criteria,
      } = filter
      const startDate = start_date ? `start_date=${start_date}` : null
      const endDate = end_date ? `end_date=${end_date}` : null
      const pageNum = curPageNum ? `pageNum=${curPageNum}` : null
      const pageSize = `pageSize=${
         typeof curPageSize === 'number' ? curPageSize : 5
      }`
      const searchParams = search_criteria
         ? search_criteria.map(({ key, value }) => `${key}=${value}`).join('&')
         : null

      return [startDate, endDate, pageNum, pageSize, searchParams]
         .filter((q) => q !== null)
         .join('&')
   }, [filter])

   const { data, ...restResult } = useQuery<ResultDataType>({
      url: url ? (queryParams ? `${url}?${queryParams}` : url) : null,
      secured: true,
      fetchOptions: {
         refreshInterval: 60000,
         dedupingInterval: 5000,
         revalidateIfStale: true,
         // revalidateOnFocus: true,
         // revalidateOnMount: true
         ...options,
      },
   })

   return {
      filter,
      response: {
         data: data?.data,
         paginationInfo: data?.paginationInfo,
         ...restResult,
      },
      controlMethods: {
         handleChangeEndDate,
         handleChangePageNum,
         handleChangePageSize,
         handleChangeStartDate,
         handleAddSearchCriteria,
         handleRemoveSearchCriteria,
      },
   }
}

export default useFilteredQuery
